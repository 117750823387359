import React from 'react';
import {SwitchComponent} from "../switch-component/switch-component";
import {isDarkTheme, setTheme} from "./dark-mode";
import './index.css';

export const DarkModeToggler = (props: {onChange?: any; className?: string;}) => (
	<div
		className={
			"dark-mode-toggle-component " +
			(!!props.className ? (' '+ props.className) : "")
		}
	>
		<span>Dark Mode</span>
		<SwitchComponent
			active={ isDarkTheme() }
			onToggle={ (newValue: boolean) => {
				setTheme(!!newValue);
				if( typeof props.onChange === 'function' ) {
					props.onChange( !!newValue );
				}
			}}
			className={ props.className }
		/>

	</div>
)
