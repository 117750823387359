import React, { useEffect, useRef, useState } from 'react';
import { cache } from "../../global";
import ActionButton from "../ActionButton";
import { showlog, beautify } from '@liparistudios/js-utils';
import './phone-number-panel.css';
// import { SignOn } from '../../service/signon';
import { isPropertySignature } from 'typescript';
import './phone-number-panel.css';

export const PhoneNumberPanel = (props: {onClose?: any;}): JSX.Element => {

	// const [accessPopup, toggleAccessPopup] = useState<number>(() => 0);
	const [socialAccessName, setSocialAccess] = useState<string>();
	const [waiting, setWait] = useState<boolean>();

	useEffect(() => {

		let cellNumberContainer: any = document.getElementById('cellNumber');
		if (!!cellNumberContainer) {
			cellNumberContainer.focus();
		}

	}, []);


	const inputRef = useRef(null);


	/* PRODUCTION
	*/

	// let signOn: any = {};
	const [signOn, setSignOn] = useState<any>( );
	useEffect( () => {
		showlog('useeffect di avvio');
		showlog( window );


		// return ( () => {


		if( !signOn ) {
			showlog('useEffect di avvio RETURN');

			const scriptEle = document.createElement("script");
			scriptEle.type = "text/javascript";
			scriptEle.async = true;
			scriptEle.src = `${ process.env.REACT_APP_AUTH_SERVICE_URL }/signon.min.js`;
			scriptEle.id = 'ssoPackage';

			scriptEle.addEventListener("load", (ev) => {

				showlog('script SSO caricato');
				// @ts-ignore
				setSignOn(window.SignOn);

			});

			scriptEle.addEventListener("error", (ev) => {
				console.log({
					status: false,
					message: `Failed to load the script ${ process.env.REACT_APP_AUTH_SERVICE_URL }/static/js/package/signon.min.js`
				});
				alert(`Failed to load the script ${ process.env.REACT_APP_AUTH_SERVICE_URL }/static/js/package/signon.min.js`); // TODO attenzione da eliminare
			});

			if( !document.getElementById('ssoPackage') ) {
				document.body.appendChild(scriptEle);
			}
		}




		// })
	});





	return (
		<div
			className={'popup-list-backpan-blurred'}
			onClick={clickEvent => {
				clickEvent.stopPropagation();
				clickEvent.preventDefault();
				// toggleAccessPopup(0);
				props.onClose();
			}}
		>
			<div
				className={'modal-container-blurred large role-icon-panel'}
				onClick={clickEvent => {
					clickEvent.stopPropagation();
					clickEvent.preventDefault();
				}}

			>
				<div
					className={'element-icon-button pannel-element'}
					onClick={clickEvent => {
						clickEvent.stopPropagation();
						clickEvent.preventDefault();
						// toggleAccessPopup(2);
					}}
				>
					{/*
					<img
						src={'/imgs/oldTelephone.png'}
					/>
					*/}
					<span>
						Inserisci il tuo numero di telefono cellulare
						{
							// cache?.language.phoneNumber
						}
					</span>
				</div>

				<div
					className={'element-icon-button pannel-element'}
					onClick={clickEvent => {
						clickEvent.stopPropagation();
						clickEvent.preventDefault();
					}}
				>
					<input
						type={'number'}
						id={'cellNumber'}
						className='form-field-text-editable landing-input'
						// style={{ background: '#fff5' }}
						onKeyUp={(keyUpEvent: any) => {
							if (keyUpEvent.key === 'Enter' || keyUpEvent.keyCode === 13) {
								// stessa funzione del tasto
								setWait(true);
								if (!!signOn) {

									showlog('controllo inputref');
									showlog(inputRef);
									// @ts-ignore
									SignOn.smsAuth('39'+ inputRef.current?.value, process.env.REACT_APP_API_KEY);
								}
							}
						}}
						onFocus={focusEvent => {
							// showlog( focusEvent.nativeEvent );
							// showlog( focusEvent.currentTarget );
							// showlog( focusEvent.target.getNativeRef() );
							// showlog( this.input );
						}}
						// ref={input => { this.input = input}}
						ref={inputRef}
					/>
					<ActionButton
						id={'cellNumberButton'}
						buttonText={

							cache?.language.ok || 'OK'
						}
						action={() => {

							if (!!signOn) {
								setWait(true);

								showlog('controllo inputref 2');
								showlog(inputRef);
								// @ts-ignore
								signOn.smsAuth('39'+ inputRef.current?.value, process.env.REACT_APP_API_KEY)
							}
							else {
								return Promise.reject('Manca il SignOn');
							}


						}}
						onResult={(result: any) => {
							console.log('action button result');
							console.log(result);
						}}
						onProgress={() => {
							console.log('action button progress');
						}}
						onError={(e: any) => {
							console.log('action button error');
							console.log(e);
						}}
					/>
				</div>

			</div>
		</div>
	);
}
