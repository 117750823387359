export const httpTimer = {
	start: (seconds?: number) => {
		let time = seconds || 25 * 1000;
		return (
			new Promise((success, fail) => {
				setTimeout( () => fail("HTTP CALL WAITING TIME EXPIRED"), time );
			})
		)
	}
}
export const httpMethod = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
};

export const httpCall = (
	type: string,
	endpoint: string,
	body?: any,
	headersMap?: object | undefined
) => {
	let requestOptions: {method: string; headers: object; redirect: string; body: string | undefined | null} = {
		method: type,
		headers: Object.assign({'Content-Type': 'application/json'}, (!!headersMap ? {...headersMap} : {})),
		redirect: 'follow',
		body: (
			type !== httpMethod.GET
				? (
					typeof body === 'string'
						? body
						: JSON.stringify(body)
				)
				: null
		),
		// credentials: "include"
	};


	// correzione
	endpoint = endpoint.split('//').join('/');

	let cUrl: string = `curl${ (requestOptions.method === httpMethod.GET) ? "" : (" -X "+ requestOptions.method)} `;
	cUrl += `${ endpoint } `;
	// headers
	cUrl +=
		Object.keys(requestOptions.headers)
		// @ts-ignore
		.map( (header: string) => `-H '${ header }':'${ requestOptions.headers[ header ] }'`)
		.join(" ")
	;
	// body
	if( !!requestOptions.body ) {
		cUrl += ` -d '${ requestOptions.body }'`
	}
	console.log( "%c"+ cUrl, "color:#ff0;");
	let startTime = (new Date()).getTime();


	return Promise.race([
		httpTimer.start(),
		// @ts-ignore
		fetch(endpoint, requestOptions)
		.then(result => {
			if (!!result && !!result.headers) {
				if (result.headers.get('Content-Type')?.indexOf('json') !== -1) {
					return result.json();
				}
				if (result.headers.get('Content-Type')?.indexOf('plain') !== -1) {
					return result.text();
				}
				if (result.headers.get('Content-Type')?.indexOf('text') !== -1) {
					return result.text();
				}
				if (
					result.headers.get('Content-Type')?.indexOf('octet-stream') !== -1
				) {
					return result.blob();
				}
			}
			return result;
		})

		.then(result => {
			// if( result.headers.get("Content-Type").indexOf("json") !== -1 ) return result.data;
			console.log( `%cRESPONSE [${ ((new Date()).getTime()) - startTime }ms]`, "color:#ff0;");
			console.log(result);
			return result;
		})

		.catch(error => {
			return Promise.reject(error);
		}),
	]);
};
