import React from 'react';
import './index.css';
import {WaitingWheel} from "../waiting-wheel";
import {loadAllLanguages} from "./index";
export default class LanguageSelector extends React.Component
    <
        any,
        {
            open: boolean;
            currentLang: { name: string; code: string; },
            clickCoords: { x: number; y: number; },
            languagesList?: {name: string; code: string; flagUrl: string;}[],
            loadLanguagesError?: any
        }
    >
{


    state = {
        open: false,
        currentLang: {
            name: this.props.currentLanguageName,
            code: this.props.currentLanguageCode
        },
        clickCoords: { x:0, y:0 },
        languagesList: [],
        loadLanguagesError: null
    }

    constructor(props: any) {
        super( props );
    }

    render() {

        console.log('render language component');
        console.log(this.state);

        return (
            <React.Fragment>
                <div
                    id='language-selector'
                    className='language-selector'
                    onClick={ clickEvent => {
                        console.log( clickEvent );
                        clickEvent.stopPropagation();
                        clickEvent.preventDefault();

                        this.setState({
                            ...this.state,
                            open: true,
                            clickCoords: {
                                x: clickEvent.clientX || clickEvent.pageX,
                                y: clickEvent.clientY || clickEvent.pageY
                            }
                        },
                            () => {
                                loadAllLanguages()
                                    .then( list => {


                                        this.setState({
                                            ...this.state,
                                            languagesList: list
                                        },
                                            () => {

                                                if( typeof this.props.onOpen === 'function' ) {
                                                    this.props.onOpen();
                                                }

                                            }
                                        )


                                    })
                                    .catch(e => {
                                        console.log('errore al get languages list');
                                        console.error( e );
                                        this.setState({
                                            ...this.state,
                                            loadLanguagesError: e
                                        })
                                    })
                                ;
                            }
                        )
                    }}
                >
                    <img
                        src={`/language/flag/${ this.props.currentLanguage }`}
                        className={''}
                    />
                    <div>{ this.props.currentLanguageName }</div>
                </div>
                {
                    !!this.state.open
                        ? (
                            <div
								className={'language-list-backpan'}
                                onClick={ clickEvent => {
                                    clickEvent.stopPropagation();
                                    clickEvent.preventDefault();
                                    if( typeof this.props.onClose === 'function' ) {
                                        this.props.onClose();
                                    }
                                    this.setState({
                                        ...this.state,
                                        open: false
                                    })
                                }}
                            >
                                <div className={'language-list-container'} id={'language-list-container'}>
                                    {
                                        (!!this.state.languagesList && (this.state.languagesList.length > 0))
                                            ? (
                                                this.state.languagesList
                                                    .map( (lan: {code: string; name: string; flag: string; }, index: number) => (
                                                        <div
                                                            className={
                                                                'language-list-row'
                                                                + ( (index === (this.state.languagesList.length -1)) ? ' language-list-row-last' : '')
                                                                + ( (index === 0) ? ' language-list-row-first' : '')
                                                            }
                                                            onClick={ clickEvent => {
                                                                clickEvent.stopPropagation();
                                                                clickEvent.preventDefault();
                                                                this.setState({
                                                                    ...this.state,
                                                                    languagesList: []
                                                                },
                                                                    () => {
                                                                        document.cookie = 'lang='+ lan.code;
                                                                        window.location.href = window.location.href;
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <img
                                                                src={ lan.flag }
                                                            />
                                                            <div className={''}>{ lan.name }</div>
                                                        </div>
                                                    ))
                                            )
                                            : (
                                                <WaitingWheel
                                                    error={ !!this.state.loadLanguagesError }
                                                    color={'#0d0'}
                                                    className={'language-list-inwait-wheel'}
                                                />
                                            )
                                    }
                                </div>
                            </div>
                        )
                        : null
                }
            </React.Fragment>
        )
    }

    componentDidMount(): void {

    }
}
