import React from 'react';
import { WaitingWheel } from '../waiting-wheel';
import './index.css';
import {cache} from "../../global";
// import {cache} from "../../global";



type WaitingOverComponentType = {
    title?: string | JSX.Element;
    message?: string | JSX.Element;
    color?: string;
    titleClassName?: string;
    panelClassName?: string;
};

export const WaitingOverPageComponent = (props: WaitingOverComponentType) => {


    return (
        <div
            className={'popup-list-backpan waiting-over-component-backpan'}
            id={'waitingBackPan'}
        >
            <div
                className={ props.panelClassName || '' }
            >
                <WaitingWheel
                    error={false}
                    color={!!props.color ? props.color : 'var(--color-active-selection-2)'/*'#3f5'*/}
                />
                <span className={ props.titleClassName }>
                    {
                        !!props.title
                            ? props.title
                            // @ts-ignore
                            : ( cache?.language?.inwaiting || 'In Attesa ...' )
                    }
                </span>
            </div>
            {
                !!props.message
                    ? (
                        <div>
                            {props.message}
                        </div>
                    )
                    : null
            }
        </div>
    );

}
