import React, { useState } from 'react';
import './switch-component-style.css';

type SwitchComponentType = {
    active: boolean;
    isReadOnly?: boolean;
    className?: string;
    onToggle?: any;
}

export const SwitchComponent = (props: SwitchComponentType) => {

    const [checked, toggle] = useState(props.active);

    return (
        <div
            className={
                "switch-component-container " +
                (!!props.isReadOnly ? ' locked' : ' alive') +
                (!!props.className ? (' '+ props.className) : "")
            }
            onClick={clickEvent => {
                clickEvent.stopPropagation();
                clickEvent.preventDefault();
                if (!props.isReadOnly) {
                    if (typeof props.onToggle === 'function') {
                        props.onToggle(!checked);
                    }
                    toggle(!checked);
                }
            }}
        >
            <div
                className={
                    "switch-component" +
                    (
                        !!checked
                            ? " tsc-active"
                            : " tsc"
                    )
                    + (
                        !!props.isReadOnly
                            ? ' tsc-disabled'
                            : ''
                    )
                }

            >
                <div
                    className={
                        "switch-component-inside" +
                        (
                            !!checked
                                ? " tsci-active"
                                : " tsci"
                        )
                        + (
                            !!props.isReadOnly
                                ? ' tsci-disabled'
                                : ''
                        )
                    }
                />
            </div>
        </div>
    );
}
