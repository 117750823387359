import {httpCall, httpMethod} from "../../service/http";

export const loadAllLanguages = () => {
	return (
		httpCall( httpMethod.GET, '/languages')
			.then(list => (
				list
					.map( (lan: any) => ({
						...lan,
						flag: '/language/flag/'+ lan.code
					}))
			))
			.catch( e => Promise.reject( e ) )
	)
}

export { default as default } from './language-selector';

