import {readIsDarkTheme, saveTheme} from "./dark-mode-service";

export const setTheme = (value: boolean) => {
	let theme = !!value ? 'dark' : 'light';
	// @ts-ignore
	document.querySelector("html").setAttribute("data-theme", theme);
	saveTheme(theme);
};


export const isDarkTheme = (): boolean => readIsDarkTheme();
