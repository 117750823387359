import React from 'react';
import './index.css';
import {WaitingWheel} from "../waiting-wheel";

const httpTimer = {
    start: (seconds?: number) => {
        let time = seconds || 30 * 1000;
        return (
            new Promise((success, fail) => {
                setTimeout(() => fail("HTTP CALL WAITING TIME EXPIRED"), time);
            })
        )
    }
}



/**
 * Compenente button
 * al click del bottone
 */
export default class ActionButton extends React.Component<
    {
        buttonText?: any;
        waitingText?: any;
        action?: any;
        promise?: any;
        onResult?: any;
        onError?: any;
        onProgress?: any;
        dontStopPropagation?: boolean;
        className?: string;
        noWait?: boolean;
        disabled?: boolean;
        id?: string;
        ref?: any;
    },
    {
        waiting: boolean;
        timeout: boolean;
        done: boolean;
        error: any
    }> {

    state = {
        waiting: false,
        timeout: false,
        error: null,
        done: false
    }

    constructor(props: any) {
        super(props);
    }

    render() {



        // console.log('render action button');
        // console.log( this.state );


        return (
            <div
                ref={ this.props.ref }
                id={ this.props.id || `button_${ (new Date()).getTime() }` }
                className={'action-button ' + (!!this.props.className ? this.props.className : '') + (!!this.props.disabled ? ' disabled' : '')}
                onClick={clickEvent => {


                    if( !this.state.error && !this.state.timeout && !this.state.waiting && !this.props.disabled) {

                        if (!!this.props.dontStopPropagation) {

                        }
                        else {
                            clickEvent.stopPropagation();
                            clickEvent.preventDefault();
                        }
                        this.setState({
                            ...this.state,
                            waiting: true
                        },
                            () => {

                                if (typeof this.props.onProgress === 'function') {
                                    this.props.onProgress();
                                }

                                let actionPromise = Promise.resolve();

                                if ((typeof this.props.promise) === 'function') {
                                    actionPromise = this.props.promise;
                                }
                                if ((typeof this.props.action) === 'function') {
                                    actionPromise = this.props.action;
                                }

                                Promise.race([
                                    httpTimer.start(),
                                    // @ts-ignore
                                    actionPromise()
                                ])
                                    .then(result => {

                                        if ((typeof this.props.onResult) === 'function') {
                                            this.props.onResult(result);
                                        }

                                        try {

                                            this.setState({
                                                ...this.state,
                                                error: null,
                                                waiting: false,
                                                done: true
                                            },
                                                () => {
                                                    // if ((typeof this.props.onResult) === 'function') {
                                                    //     this.props.onResult(result);
                                                    // }
                                                })

                                        }
                                        catch (e) {
                                            throw e;
                                        }

                                    })
                                    .catch(e => {

                                        if (e === 'HTTP CALL WAITING TIME EXPIRED') {
                                            this.setState({
                                                ...this.state,
                                                error: null,
                                                // waiting: false,
                                                timeout: true
                                            },
                                                () => {
                                                    if (typeof this.props.onError === 'function') {
                                                        this.props.onError(e);
                                                    }
                                                })
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                error: e,
                                                waiting: false
                                            },
                                                () => {
                                                    if (typeof this.props.onError === 'function') {
                                                        this.props.onError(e);
                                                    }
                                                })
                                        }

                                        setTimeout(
                                            () => {
                                                this.setState({
                                                    ...this.state,
                                                    error: null,
                                                    waiting: false,
                                                    timeout: false
                                                })
                                            },
                                            2000
                                        )

                                    })


                            })

                    }

                }}
            >
                {/* <div className='action-button-status'> */}
                    {
                        ((!!this.state.waiting || !!this.state.error) && !this.props.noWait )
                            ? (
                                <WaitingWheel
                                    error={ !!this.state.error }
                                    timeout={ !!this.state.timeout }
                                    className='action-button-status'
                                />
                            )
                            : null
                    }
                {/* </div> */}
                <div className=''>
                    {
                        (!!this.state.waiting && !this.props.noWait)
                            ? (
                                this.props.waitingText || 'In Attesa...'
                            )
                            : (
                                !!this.state.error
                                    ? (<span className={'error-label-text'}>{ this.state.error }</span>)
                                    : (                                        
                                        this.props.buttonText || 'OK'
                                    )
                            )
                    }
                </div>
            </div>
        )
    }

    componentDidMount(): void {

    }
}

