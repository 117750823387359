import React, {useEffect, useRef, useState} from 'react';
import { showlog, beautify } from '@liparistudios/js-utils';
import {cache, cachelog} from "./global";
import {WaitingOverPageComponent} from "./components/WaitingOverComponent";
import ActionButton from "./components/ActionButton";
import LanguageSelector from "./components/Language";
import {CryptoSectionComponent} from "./components/secure-section-component/crypto-section-component";
import {DarkModeToggler} from "./components/dark-mode-toggler-component/dark-mode-toggler";
import {TargetDeviceTagsComponent} from "./components/target-device-tags-component/target-device-tags-component";
import {isDarkTheme} from "./components/dark-mode-toggler-component/dark-mode";
import {PhoneNumberPanel} from "./components/phone-number-panel/phone-number-panel";
import './landing.css';
import './mobile.css';
import './components/phone-number-panel/phone-number-panel.css';
import './slide-1.css';
import './slide-2.css';
import './slide-3.css';
import './slide-4.css';
import './slide-securement.css';




function App() {



	const [accessPopup, toggleAccessPopup] = useState<number>(() => 0);
	const [error, setError] = useState<string>();
	const [refreshed, refresh] = useState<any>();
	const [mobileMenuOn, toggleMobileMenu] = useState<boolean>();

	// controllo lingua
	// dovrebbe essere usato sopltanto in dev
	const [languagePackReached, setLanguagePack] = useState<boolean>(() => false);


	/*
	useEffect(() => {
		const onPageLoad = () => {
			setTimeout(
				() => {
					setSplashScreenVanishClassName('vanish');
				},
				500
			)
		};

		// Check if the page has already loaded
		if (document.readyState === 'complete') {
			onPageLoad();
		} else {
			window.addEventListener('load', onPageLoad);
			// Remove the event listener when component unmounts
			return () => window.removeEventListener('load', onPageLoad);
		}
	}, []);
	*/


	if (!cache.language && !languagePackReached) {

		fetch('/language-pack')
			.then(response => response.json())
			.then(response => {
				cache.language = response;
				(window as any).language = response;
				setLanguagePack(true);
			})
			.catch(e => {
				console.log('get languagePack', e);
			})
		;


		return (
			// <WaitingWheel error={false}/>
			<WaitingOverPageComponent
				color={'var(--main-selection-color)'}
				titleClassName={'contrast-color'}
				title={ !error ? '' : 'Errore' }
				message={ error }
			/>
		)

	}


	return (
		<div className="App">


			{/*
				(!!isInLoading)
					? (
						<div id={'splash-screen'} className={ splashScreenVanishClassName }>
							<img
								src={'/imgs/logo.png'}
							/>
						</div>
					)
					: null
			*/}


			{
				!!accessPopup
					? (
						(accessPopup === 1)
							? (
								<PhoneNumberPanel
									onClose={ (closeEvent: any) => {
										toggleAccessPopup(0);
									}}
								/>
							)
							: null
					)
					: null
			}

			<div id={'header'}>
				<div className=''>
					<img
						id={'logo'}
						className={'desktop'}
						src={
							!!isDarkTheme()
								? '/imgs/logo_horizontal_dark.png'
								: '/imgs/logo_horizontal_light.png'
						}
					/>
					<img
						id={'logo'}
						className={'mobile'}
						src={
							!!isDarkTheme()
								? '/imgs/logo_name_light.png'
								: '/imgs/logo_name_dark.png'
						}
					/>
				</div>
				<div >

					<DarkModeToggler
						onChange={ (isDarkMode: boolean) => {
							refresh( (new Date()).getTime() )
						}}
						className='desktop'
					/>


					<LanguageSelector
						currentLanguage={
							cache?.language.languageCode
						}
						currentLanguageName={
							cache?.language.languageName
						}

					/>
					<ActionButton
						buttonText={
							cache?.language.privateArea || 'Area Privata'
						}
						action={() => {
							return (
								new Promise((onFinish, onFail) => {

									// toggleAccessPopup(1);

									// inputRef.current.focus();


									setTimeout(
										function () {
											onFinish(true);
										},
										30
									);

								})
							);
						}}
						onResult={(result: any) => {
							console.log('acttion button result');
							console.log(result);
							toggleAccessPopup(1);
						}}
						onProgress={() => {
							console.log('acttion button progress');
						}}
						onError={(e: any) => {
							console.log('acttion button error');
							console.log(e);
						}}
						className='desktop'
					/>

				</div>

				<div
					id={'menu'}
					className={'mobile'}
				>
					<img
						src={
							!!isDarkTheme()
								? '/imgs/profile_gray.png'
								: '/imgs/profile.png'
						}
						onClick={ (clickEvent: any) => {
							toggleMobileMenu( true );
						}}
					/>
				</div>

			</div>
			<div id={'page'}>


				{
					!!mobileMenuOn
						? (
							<div
								className={'menu-panel popup-list-backpan-blurred'}
								onClick={clickEvent => {
									clickEvent.stopPropagation();
									clickEvent.preventDefault();
									toggleMobileMenu( false );
								}}
							>
								<div
									className={'mobile-settings-panel'}
									onClick={clickEvent => {
										clickEvent.stopPropagation();
										clickEvent.preventDefault();
									}}

								>
									<DarkModeToggler
										onChange={ (isDarkMode: boolean) => {
											refresh( (new Date()).getTime() )
										}}
									/>



									<ActionButton
										buttonText={
											cache?.language.privateArea || 'Area Privata'
										}
										action={() => {
											return (
												new Promise((onFinish, onFail) => {

													// toggleAccessPopup(1);

													// inputRef.current.focus();


													setTimeout(
														function () {
															onFinish(true);
														},
														30
													);

												})
											);
										}}
										onResult={(result: any) => {
											console.log('acttion button result');
											console.log(result);
											toggleMobileMenu( false );
											toggleAccessPopup(1);
										}}
										onProgress={() => {
											console.log('acttion button progress');
										}}
										onError={(e: any) => {
											console.log('acttion button error');
											console.log(e);
										}}
									/>
								</div>
							</div>
						)
						: undefined
				}


				{/* ---------------------------------------------------------------------------------------------------------------------- */}

					<div id={'slide_01'}>

						<div className={'left'}>
							<label>{ (window as any).language.landing_01 }</label><br/>
							<span>
								{ (window as any).language.landing_02 }<br/>
								<br/>
								{ (window as any).language.landing_03 }<br/>
								<br/>
								{ (window as any).language.landing_04 }
							</span>
							<br/>
							<br/>
							<TargetDeviceTagsComponent/>
						</div>
						<div
							className={'right'}
							style={{
								backgroundImage: (
									!!isDarkTheme()
										? "url('/imgs/presentation_01_1_fliped.png')"
										: "url('/imgs/presentation_01_1_fliped.png')"
								)
							}}
						>
							{/*<img src={'/imgs/presentation_01_1_fliped.png'} />*/}
							<div id={'slide_01_gradiente'}>
								<div id={'slide_01_pannello'}>
									<label className={'smaller'}>{ (window as any).language.landing_05 }</label>
									<label>Cashback</label>
									<span>
										Monetizza i tuoi accordi e fai volare il tuo business!!
									</span>
								</div>

							</div>
						</div>

					</div>




				{/* ---------------------------------------------------------------------------------------------------------------------- */}
				<div
					id={'slide_02'}
					className={'slides'}
					style={{
						backgroundImage: (
							!!isDarkTheme()
								? "url('/imgs/presentation_01_20.png')"
								: "url('/imgs/presentation_01_15.png')"
						)
					}}
				>
					<div>
						<span>Cos’è City Partner</span>
						Siete titolari di un’attività che si rivolge ai turisti e volete massimizzare le vostre entrate
						e creare esperienze indimenticabili per i vostri ospiti?<br/>
						Non cercate oltre.<br/><br/>
						Vi presentiamo City Partner, l'ultima web app progettata esclusivamente per le aziende
						del settore alberghiero come la vostra.
						Sfruttate la nostra potente piattaforma per
						stabilire partnership con bar, ristoranti e negozi locali.<br/><br/>
						Guadagnate commissioni vantaggiose,
						premi cashback e deliziate i vostri ospiti con sconti e coupon esclusivi, il tutto con
						una semplice raccomandazione.<br/><br/>
						Entrate a far parte di City Partners oggi stesso e implementate
						nuovi flussi di reddito attraverso partnership con attività locali.
					</div>
				</div>





				{/* ---------------------------------------------------------------------------------------------------------------------- */}


				<div
					id={'slide_03'}
					className={'slides'}
					style={(
						!!isDarkTheme()
							? ({
								backgroundImage: "url('/imgs/presentation_01_12.png')",
								backgroundPositionY: '0%',
								minHeight: '440px'
							})
							: ({
								backgroundImage: "url('/imgs/presentation_03_8.png')"
							})
					)}
				>
					<div>
						<span>Come funziona City Partner</span>
						<div>
							<div>
								<span>Espandi la rete di imprese locali</span>
								<div>Crei partnership con bar, ristoranti, negozi ed altre attività turistiche della tua città</div>
							</div>
							<div>
								<span>Delizia i tuoi ospiti con sconti esclusivi</span>
								<div>Consigli i tuoi ospiti di acquistare da loro con sconti esclusivi</div>
							</div>
							<div>
								<span>Guadagna commissioni su ogni acquisto degli ospiti</span>
								<div>Guadagni una percentuale su ogni acquisto che fanno</div>
							</div>
							<div>
								<span>Massimizza i tuoi guadagni </span>
								<div>Più acquisti raccomandi, più commissioni incassi!</div>
							</div>
						</div>
					</div>
				</div>
				
				
				<div
					id={'video'}
					className={'slides'}
				>
					<div
						style={{
							margin: '1rem auto',
							width: '90%'
						}}
					>
						<video
							src={
								((window as any).language.languageCode === 'it-IT')
									? 'https://citypartner.it/video/CityPartner_IT.mov'
									: 'https://citypartner.it/video/CityPartner_EN.mov'
							}
							poster='/imgs/logo.png'
							width={'100%'}
							controls
						/>
					</div>
				</div>
				
				
				{/* funzionamento */}
				
				<div>
					
					
					<div id={'agreement'} className={'area'}>
						
						<div className={'ballName'}>
							<img
								src={'/imgs/handshake_01.png'}
							/>
							<label style={{ top: '0px' }}>Accordo</label>
						</div>

						{/*<div className={'agreement vertical-line'}/>*/}


						<div className={'sections'}>

							<div
								id={'section_01'}
								className={'section'}
							>
								<label>Accordo garantito col partner</label>
								<span>Con i tuoi partner crea accordi reciproci in tutta serenità</span>
								<div className={'window-frame'}>
									<div className={'title-bar'}>
										<div>
											{/* left side */}
											<label>Accordo</label>
											<span className={'tag'}>new</span>
											<span>creato adesso</span>
										</div>
										<div>
											{/* right side */}
											<span>...</span>
										</div>
									</div>
									<div className={'page'}>
										<label>Testo dell'accordo</label>
										<span>Nel mezzo del cammin di nostra vita mi ritrovai per una selva oscura che la dritta via era smarrita</span>
										<br/>
										<br/>
										<br/>
										<br/>
									</div>
								</div>
							</div>


							<div
								id={'section_02'}
								className={'section'}
							>
								<div>Basterà una scansione del partner al tuo codeID per firmare l'accordo con te</div>
								<img src={'/imgs/agreement-scan_01.png'} />
							</div>

							<div
								id={'section_03'}
								className={'section'}
							>
								<label>Contrato</label>
								<span>Il sistema generera un contratto che verra firmato digitalmente tramite la TUA firma digitale e la firma digitale del partner.<br/>Le chiavi e i certificati verrano emessi in forma gratuita dal sistema</span>
								<img src={'/imgs/contract_sample.png'} />
							</div>

							<div
								id={'section_04'}
								className={'section'}
							>
								<label>Notifiche di accordo</label>
								<span>Verrà notificato l'accordo appena siglato, e riceverete una mail con i file relativi</span>
								<img src={'/imgs/sample_01.png'} />
							</div>

						</div>


					</div>





					<div id={'scanning'} className={'area'}>

						<div className={'ballName scan'}>
							<img
								src={'/imgs/barcode-scan.png'}
							/>
							<label>Scansione</label>
						</div>

						{/*<div className={'agreement vertical-line'}/>*/}


						<div className={'sections'}>

							<div
								id={'section_05'}
								className={'section'}
							>
								<label>Emissione Codice</label>
								<span>Gestisci i tuoi codici-partner o semplicemente utilizza quelli già pre-impostati</span>
								<img src={'/imgs/qrcode.png'} id={'qrcode'} className={'sizing'}/>
							</div>


							<div
								id={'section_06'}
								className={'section'}
							>
								<label>Clienti</label>
								<span>Clienti con codice</span>
								<img src={'/imgs/customers_1.png'} className={'sizing'}/>
							</div>

							<div
								id={'section_07'}
								className={'section'}
							>
								<label>Scansione</label>
								<span>I clienti fanno scansionare il codeID</span>
								<img src={'/imgs/invoices.png'} className={'sizing'}/>
							</div>

							<div
								id={'section_08'}
								className={'section'}
							/>


						</div>


					</div>





					<div id={'scanning'} className={'area'}>

						<div className={'ballName management'}>
							<img
								// src={'/imgs/stack_no_shadow.png'}
								src={'/imgs/cash.png'}
							/>
							<label>Gestione</label>
						</div>

						{/*<div className={'agreement vertical-line'}/>*/}


						<div className={'sections'}>

							<div
								id={'section_05'}
								className={'section'}
							>
								<label>Portale</label>
								<span>I clienti fanno scansionare il codeID</span>
								<img src={'/imgs/invoices.png'} className={'sizing'}/>
							</div>


							<div
								id={'section_06'}
								className={'section'}
							>
								<label>App</label>
								<span>Tutta la situazione a portata di mano</span>
								<img src={'/imgs/invoices.png'} className={'sizing'}/>
							</div>

							<div
								id={'section_07'}
								className={'section'}
							>
								section
							</div>

							<div
								id={'section_08'}
								className={'section'}
							>
								section
							</div>

						</div>


					</div>









				</div>



				{/* sicurezza */}


				<div id={'securement'}>
					<div className={'left'}>

						<div>Sicurezza</div>
						<span>
							La sicurezza dei dati<br/>
							I tuoi dati sono al sicuro grazie ad un sistema di cifratura,
							i tuoi accordi si tramutano in contratti con firma digitale, il tuo accesso &egrave; garantito
							da provider internazionali, tutto grazie al sistema fornito dal servizio MetaSEC
						</span>
						<TargetDeviceTagsComponent/>
					</div>
					<CryptoSectionComponent
						width={640}
						height={360}
						backgroundImage={
							!!isDarkTheme()
								? '/imgs/presentation_03_1.png'        // dark mode
								: '/imgs/presentation_01_19.png'        // light mode
							// : '/imgs/presentation_01_12.png'
						}
						onClickCallBack={() => {
							window.open( process.env.REACT_APP_AUTH_SERVICE_URL )
						}}
						// className={'right'}
					/>
				</div>







				{/* per te */}

				{/* 24 e 27 */}
				<div
					id={'slide_04'}
					className={'slides'}
					style={(
						!!isDarkTheme()
							? ({
								backgroundImage: "url('/imgs/presentation_01_24.png')"
							})
							: ({
								backgroundImage: "url('/imgs/presentation_01_27.png')",
								backgroundPositionY: '60%'
							})
					)}
				>
					<div
						style={(
							!!isDarkTheme()
								? ({})
								: ({
									background: 'var(--small-opacity-theme-color)'
								})
						)}
					>
						<span>Vantaggi per Te</span>
						<ul>
							<li>Nuovo canale di guadagno passivo</li>
							<li>Maggiore visibilità della tua struttura</li>
							<li>Più valore per i tuoi ospiti</li>
						</ul>

					</div>
					<div
						style={(
							!!isDarkTheme()
								? ({})
								: ({
									background: 'var(--small-opacity-theme-color)'
								})
						)}
					>
						<span>Vantaggi per i tuoi partner</span>
						<ul>
							<li>Incremento di clienti da te indirizzati</li>
							<li>Maggiore visibilità della loro attività</li>
						</ul>
						<em>Potrebbero essere loro stessi a venirti a cercare</em>
					</div>
					<div
						style={(
							!!isDarkTheme()
								? ({})
								: ({
									background: 'var(--small-opacity-theme-color)'
								})
						)}
					>
						<span>Vantaggi per i tuoi clienti</span>
						Saranno
					</div>
				</div>



			</div>
			<div>
				Footer
			</div>
		</div>
	);
}

export default App;
