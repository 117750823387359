import moment from 'moment';
import {
	showlog,
	onlyNotNull,
	DATE_FORMAT_IT,
	beautify,
	getLastElementOr,
	extractErrorMessage,
	isNotEmptyArray,
	emoj,
	EMOJ
} from '@liparistudios/js-utils';


type cacheType = {
	error?: any;
	language?: any;
}

export let cache: cacheType = {};

export const cachelog = () => {
	showlog(
		'%c  CACHE  ',
		'background:#f0f; color:#eee; border-radius:100px; font-weight: bold; font-size: 1.2em;',
	);
	showlog( cache );
}

