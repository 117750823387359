import React from "react";
import './index.css';

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number): { x: number; y: number; } => {
	let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

	return {
		x: centerX + (radius * Math.cos(angleInRadians)),
		y: centerY + (radius * Math.sin(angleInRadians))
	};
}

const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number): string => {

	let start = polarToCartesian(x, y, radius, endAngle);
	let end = polarToCartesian(x, y, radius, startAngle);

	let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	let d = [
		"M", start.x, start.y,
		"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
	].join(" ");

	return d;
}


export const WaitingWheel = (props: {
	error: boolean;
	timeout?: boolean;
	className?: string;
	color?: string;
}) => (
	<svg
		width="20px"
		height="20px"
		viewBox="0 0 40 40"
		className={'status-in-wait ' +( !!props.className ? props.className : '')}
	>
		{
			(!props.error && !props.timeout /*&& !props.done*/)
				? (
					<path
						d={describeArc(20, 20, 14, 0, 280)}
						// strokeMiterlimit={}
						strokeLinecap={"round"}
						stroke={ !!props.color ? props.color : "#fff"}
						strokeWidth="4"
						fill="#fff0"
					/>
				)
				: (
					<circle
						cx={20}
						cy={20}
						r={16}
						stroke={
							!!props.error
								? "#f35"
								: (
									!!props.timeout
										? "#18f"
										: "#1f3"
								)
						}
						strokeWidth="6"
						fill="transparent"
					/>
				)
		}
	</svg>
)
