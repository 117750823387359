export const saveTheme = (theme: string): void => {
	localStorage.setItem("theme", theme);
	return;
}

export const readIsDarkTheme = (): boolean => (
	!!window.localStorage.getItem('theme')
		? ( window.localStorage.getItem('theme') === 'dark' )
		: (
			// selezione in automatica in base al sistema
			(!!window.matchMedia("(prefers-color-scheme: dark)") && !!window.matchMedia("(prefers-color-scheme: dark)").matches)
		)
)
